
















































































































































































import Component from "vue-class-component";
import { mapState } from "vuex";
import { Form as ElForm} from 'element-ui'
import Vue from "vue";
import {
  getBuildingHouseList,
  LandBlockType,
  HouseListItem,
  RoomInfo,
  ApplyForm,
  buildingLeaseApply,
  SelectFactoryApplyForm,
} from "@/common/api/modules/smartLocation";
import {phoneRuleValidator} from "@/common/utils"
import RoomSelector from "./components/RoomSelector/index.vue";
import Uploader from "./components/uploader.vue";
/** @desc 类型拓展  */
type HouseListItemExpand = HouseListItem & {
  /** @desc 是否选中 */
  info?: RoomInfo;
};
@Component({
  components: { RoomSelector, Uploader },
})

export default class SelectFactory extends Vue {
  //data

  /** @desc 楼层列表 */
  list: HouseListItemExpand[] = [];
  /** @desc 当前展开楼层 */
  curExpandFloor: string = "1";
  /** 
   * @type {ApplyForm}
   * @desc 选房申请表单 
   * */
  applyForm:SelectFactoryApplyForm={
    roomId:-1,
    contactPerson: '',
    phone: '',
    standardWorkshop:[],
    standardBusinessLicense:[],
    standardLegalPersonIdCard:[],
    standardPowerAttorneyIdCard:[],
    standardApplicationReport:[]

  }
  /** @desc 是否显示申请表弹框 */
  showApplyDialog = false;
  /** @desc 申请提交表单验证规则 */
   rules = {
        contactPerson: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, validator: phoneRuleValidator, trigger: 'blur' }],

        // standardWorkshop: [{ required: true, message:"请上传温州市瓯江口新区标准厂房申请表", trigger: 'blur' }],
        // standardBusinessLicense: [{ required: true, message:"请上传营业执照复印件", trigger: 'blur' }],
        // standardLegalPersonIdCard: [{ required: true, message:"请上传法人身份证复印件", trigger: 'blur' }],
        // standardPowerAttorneyIdCard: [{ required: true, message:"请上传委托书及被委托人身份证复印件", trigger: 'blur' }],
        // standardApplicationReport: [{ required: true, message:"请上传书面申请报告", trigger: 'blur' }],
   }



  //computed
  get build() {
    return this.$route.params.build;
  }
  get land(): LandBlockType {
    return <LandBlockType>this.$route.params.land;
  }

  get notLeasedNum():number{
    return this.list.reduce((sum,item)=>{
      for(let key in item){
        if(item[key] instanceof Object && item[key].leaseStatus === '0'){
           sum ++
        }
      }
      return sum
    },0)

  }

  // life cycle
  created() {
    console.log("created周期");
    this.getData();
  }
  //methods
  
   /** @desc 申请表单提交事件 */
   async handleSubmitApplyForm() {
     await  (this.$refs['from'] as ElForm).validate();
     let {success} = await buildingLeaseApply({...this.applyForm,land:this.land});
     if(!success)return;
     this.$message.success({ message: '申请成功', offset: 80 });
     this.showApplyDialog = false;
     this.getData();
  }

  /** @desc 房间选择器Change事件 */
  handleRoomSelectorChange(index: number, room: RoomInfo) {
    console.log("房间选择change", index, room);
    this.list[index].info = room;
  }
  async getData() {
    let data = await getBuildingHouseList({
      land: this.land,
      build: this.build,
    });
    this.list = data.map((item) => ({ ...item, info: undefined }));
    console.log("getBuildingHouseList获取到数据", data);
  }
  handleShowApplyForm(id:number){
     this.applyForm.roomId = id;
      this.showApplyDialog = true;
  }
  closeDialog() {}
  /**
   * @TODO
   * 格式化楼层名称
   */
  formatFloorName(floor: string | number) {
    let floorDic = ["-", "一", "二", "三", "四", "五"];

    return floorDic[floor as number]
      ? `第${floorDic[floor as number]}层`
      : floor;
  }
   back() {
      this.$router.go(-1);
   }
    go() {
      this.$router.push({ name: 'smartLocationOverview', params: { land: this.land, build: this.build } });
    }
}
